import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import ReactFullpage from '@fullpage/react-fullpage';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from 'react-share';

import Layout from '../components/layout';
import SEO from '../components/seo';
import localize from '../components/localize';
import RichText from '../components/richtext';
import { useClientSide } from '../hooks';

const Post = ({ data }) => {
  const {
    slug,
    publishedAt,
    mainImage,
    _rawBlogTitle,
    content,
    author,
  } = data.post;

  const [activeSection, setActiveSection] = useState();
  const afterLoad = (origin, destination) => {
    setActiveSection(destination.index);
  };

  const client = useClientSide();
  const url = client && window.location;

  return (
    <Layout
      current="BLOG"
      dePath={`/blog/${slug.current}`}
      enPath={`/en/blog/${slug.current}`}
      disableUp
      disableDown
      onClickUp={() => window.fullpage_api.moveSectionUp()}
      onClickDown={() => window.fullpage_api.moveSectionDown()}
    >
      <SEO />
      <ReactFullpage
        licenseKey={process.env.GATSBY_FULLPAGE_LICENSE}
        responsiveWidth={1020}
        scrollOverflow
        autoScrolling
        scrollingSpeed={600}
        easingcss3="cubic-bezier(0.700, 0.000, 1.000, 1.000)"
        touchSensitivity="100"
        afterLoad={afterLoad}
        render={() => (
          <div id="fullpage-wrapper">
            <StyledPostsSection className="section light fp-auto-height-responsive">
              <div className="inner">
                <div className="post-preview">
                  <div className="date big">
                    <p>{publishedAt}</p>
                  </div>
                  <div className="preview">
                    <Img
                      fluid={mainImage.image.asset.fluid}
                      alt={mainImage.alt}
                      className="img"
                    />
                    <div className="big centered text">
                      <RichText blocks={_rawBlogTitle} />
                    </div>
                  </div>
                  <div className="link big">
                    <p>
                      READ
                      <br />
                      MORE
                    </p>
                  </div>
                </div>
                {content.map((contentItem, i) =>
                  contentItem.__typename === 'SanityPostText' ? (
                    <div key={i} className="content">
                      <div className="left right-aligned">
                        <RichText blocks={contentItem._rawHeadline} />
                      </div>
                      <div className="right">
                        <RichText blocks={contentItem._rawText} />
                      </div>
                    </div>
                  ) : (
                    <div key={i} className="content">
                      <div className="left right-aligned">
                        <RichText blocks={contentItem._rawHeadline} />
                      </div>
                      <div className="img right">
                        <Img
                          fluid={contentItem.image.image.asset.fluid}
                          alt={contentItem.image.alt}
                        />
                      </div>
                    </div>
                  )
                )}
                <div className="footer">
                  <div className="left right-aligned">
                    <span className="article-by">ARTICLE BY</span>
                    <span>{author.name}</span>
                  </div>
                  <div className="right">
                    <StyledSocial>
                      <FacebookShareButton url={url}>
                        <p>FB</p>
                      </FacebookShareButton>
                      <span>|</span>
                      <LinkedinShareButton url={url}>
                        <p>LINKDN</p>
                      </LinkedinShareButton>
                      <span>|</span>
                      <TwitterShareButton url={url} via="Kr8Bureau">
                        <p>TWTR</p>
                      </TwitterShareButton>
                      <span>|</span>
                      <PinterestShareButton
                        url={url}
                        media={mainImage.image.asset.url}
                      >
                        <p>PINTRST</p>
                      </PinterestShareButton>
                    </StyledSocial>
                  </div>
                </div>
              </div>
            </StyledPostsSection>
          </div>
        )}
      />
    </Layout>
  );
};

const StyledPostsSection = styled.section`
  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    margin-bottom: var(--spacing-XL);

    .post-preview {
      padding-bottom: var(--spacing-M);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .link {
        visibility: hidden;
        pointer-events: none;
        text-align: right;
      }

      .date,
      .link {
        flex: 1;
      }

      .preview {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 2;

        .text {
          position: absolute;
          pointer-events: none;
        }

        .img {
          width: calc(var(--fontsize-1) * 4.33);
        }
      }

      .link {
        text-decoration: underline;
      }
    }

    .content,
    .footer {
      display: flex;
      padding-bottom: var(--spacing-XS);

      .left,
      .right {
        width: 50%;
        padding: 0 var(--spacing-XXS);
      }

      .img {
        width: calc(var(--fontsize-1) * 4.33);
      }

      .article-by {
        padding-right: var(--spacing-M);
      }
    }

    @media (max-width: 1020px) {
      padding: var(--spacing-L) var(--spacing-XXS) var(--spacing-M)
        var(--spacing-XXS);
      margin-bottom: 0;

      .post-preview {
        align-items: flex-start;

        .date,
        .link {
          font-size: var(--fontsize-2);
        }

        .link {
          margin-right: 0;
        }
      }

      .content {
        .right {
          width: 75%;
          padding: 0;
          padding-left: var(--spacing-XXS);
        }

        .left {
          width: 25%;
          padding: 0;
        }
      }

      .footer {
        flex-direction: column;
        .right {
          width: 100%;
          padding: 0;
        }

        .left {
          width: 100%;
          padding: 0;
        }

        .article-by {
          padding-right: var(--spacing-XS);
        }
      }
    }
  }
`;

const StyledSocial = styled.span`
  display: flex;

  span {
    padding: 0 calc(var(--spacing-XXS) / 1.5);
  }
`;

export const query = graphql`
  query($slug: String!) {
    post: sanityBlogPost(slug: { current: { eq: $slug } }) {
      _rawBlogTitle
      slug {
        current
      }
      mainImage {
        alt
        image {
          asset {
            url
            fluid(maxWidth: 3600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      publishedAt
      author {
        name
      }
      content {
        ... on SanityPostText {
          _rawHeadline
          _rawText
        }
        ... on SanityPostImage {
          _rawHeadline
          image {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

Post.propTypes = {
  data: PropTypes.object,
};

export default localize(Post);
